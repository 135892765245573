<template>
  <v-container fluid id="sectionsAndTimeBar" class="white px-6 py-4">
    <v-row align="center">
      <!-- Section Buttons Slide Group -->
      <v-col cols="12" sm="10" class="d-flex justify-start align-center pa-1">
        <span
          v-if="!$vuetify.breakpoint.mobile"
          class="grey--text text--darken-4 mr-1"
        >
          Sections :
        </span>

        <v-slide-group
          v-if="!$vuetify.breakpoint.mobile"
          class="pa-0"
          show-arrows
        >
          <template v-for="(sectionName, i) in sections">
            <v-slide-item :key="i">
              <v-btn
                @click="setSelectedSection(sectionName)"
                :disabled="superLoading"
                :elevation="
                  selectedSection == sectionName || allowSwitch ? 2 : 0
                "
                :color="selectedSection == sectionName ? 'primary' : ''"
                class="ma-1"
                rounded
                small
              >
                {{ sectionName }}
              </v-btn>
            </v-slide-item>
          </template>
        </v-slide-group>

        <!-- select visible only oon mobile -->
        <v-select
          v-else
          id="sectionSelect"
          v-model="selectedSectionLocal"
          label="Sections"
          :items="sectionsArrofObj"
          item-text="name"
          item-value="name"
          item-disabled="disabled"
          height="20"
          outlined
          dense
        ></v-select>
      </v-col>

      <!-- Time Left -->
      <v-col
        cols="12"
        sm="12"
        md="2"
        class="d-flex justify-center justify-md-end align-center pa-1"
      >
        <v-chip
          color="grey lighten-4"
          :class="
            $vuetify.breakpoint.mobile
              ? 'grey--text text--darken-4 mt-n6'
              : 'grey--text text--darken-4'
          "
        >
          Time Left: {{ timeLeftText }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
export default {
  name: "SectionsAndTimeBar",
  props: [
    "superLoading",
    "userData",
    "sections",
    "allowSwitch",
    "selectedSection",
    "timeLeftText",
  ],
  computed: {
    selectedSectionLocal: {
      // getter
      get: function () {
        return this.selectedSection;
      },
      // setter
      set: function (val) {
        this.setSelectedSection(val);
      },
    },

    sectionsArrofObj() {
      var sectionsArrofObj = [];
      this.sections.forEach((section) => {
        sectionsArrofObj.push({
          name: section,
          disabled: !this.allowSwitch,
        });
      });

      return sectionsArrofObj;
    },
  },
  data: () => ({
    dialog: false,
    instructionsLang: "en",
  }),
  components: {},
  methods: {
    setInstructionsLang(val) {
      this.instructionsLang = val;
    },
    setSelectedSection(sectionName) {
      if (this.allowSwitch) {
        this.$emit("setSelectedSection", sectionName);
      }
    },
    isSectionItemDisabled() {
      return !this.allowSwitch;
    },
  },
};
</script>