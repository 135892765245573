<template>
  <div justify="center" id="otherInstructions" class="mx-0 px-0 fill-height">
    <v-container fluid>
      <!-- Top bar -->
      <v-row
        v-if="!modalView"
        style="min-height: 6.5vh"
        class="deep-purple white--text d-flex align-center pl-3"
      >
        {{ testObj.testName }}
      </v-row>

      <!-- Page Content -->
      <v-row>
        <!-- Left Side User Data (Only on mobile) -->
        <v-col
          v-if="$vuetify.breakpoint.mobile && !modalView"
          cols="12"
          sm="12"
          md="3"
          class="d-flex align-center flex-column my-3"
        >
          <span class="text-h6">
            {{ userData.fullName }}
          </span>
        </v-col>

        <!-- Left Side Instructions (Right on Mobile) -->
        <v-col cols="12" sm="12" :md="modalView ? 12 : 9" class="ma-0 pa-0">
          <v-card height="87vh" class="d-flex flex-column" tile elevation="0">
            <!-- 'Other Instructions' Bar -->
            <v-system-bar class="deep-purple lighten-4 pa-0" height="60">
              <span class="grey--text text--darken-3 text-h6 pl-3">
                Other Important Instructions
              </span>
            </v-system-bar>

            <v-card-text class="black--text" style="min-height: 60vh">
              <!-- Instructions Language Select Row -->
              <v-row justify="end" align="center" no-gutters>
                <v-col
                  cols="12"
                  md="3"
                  class="d-flex justify-md-end justify-start align-center"
                >
                  <span class="pr-2 mt-n2 mb-1 mb-md-0 mt-md-n7 text-right">
                    View In:
                  </span>
                </v-col>
                <v-col cols="12" md="2" class="d-flex justify-end align-center">
                  <v-select
                    v-model="instructionsLangLocal"
                    @change="setInstructionsLang"
                    :items="langs"
                    item-text="text"
                    item-value="value"
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>

              <!-- 'Please read instructions carefully' -->
              <v-row justify="center">
                <p
                  v-if="instructionsLangLocal == 'en'"
                  class="text-body-2 text-center"
                >
                  Other important instructions.
                </p>
                <p v-else class="text-body-2 text-center">
                  अन्य महत्वपूर्ण अनुदेश
                </p>
              </v-row>

              <!-- Instructions Row -->
              <v-row justify="start" class="mx-1 mx-md-4">
                <!-- Table -->
                <v-col cols="12" sm="12">
                  <ol v-if="instructionsLangLocal == 'en'">
                    <li>
                      The Question Paper consists of objective type questions as
                      follows:

                      <!-- Bordered Table -->
                      <div
                        class="text-center my-9 mx-2 mx-md-12"
                        style="border: 1px black solid"
                      >
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center black--text">Number</th>
                                <th class="text-center black--text">
                                  Section Name
                                </th>
                                <th class="text-center black--text">
                                  No. of Questions
                                </th>
                                <th class="text-center black--text">Marks</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(section, i) in testObj.sections"
                                :key="i + '_eng'"
                              >
                                <td>{{ i + 1 }}</td>
                                <td class="text-transform-capitalize">
                                  {{ section }}
                                </td>
                                <td>
                                  {{
                                    testObj.selectedQuestions[section].length
                                  }}
                                </td>
                                <td>
                                  {{
                                    testObj.selectedQuestions[section].length *
                                    testObj.rewardPoints
                                  }}
                                </td>
                              </tr>
                              <tr class="font-weight-bold">
                                <td></td>
                                <td>Total</td>
                                <td>{{ testObj.questionCount }}</td>
                                <td>
                                  {{
                                    testObj.questionCount * testObj.rewardPoints
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </li>

                    <li>
                      You will be given
                      <b>{{ testObj.testDuration }}</b> minutes to attempt these
                      questions.
                    </li>

                    <li>
                      Questions will be available in
                      {{
                        testObj.isBilingual
                          ? "English and in Hindi"
                          : testObj.onlySecondaryLang
                          ? "Hindi"
                          : "English"
                      }}. To see a given question in another language, a
                      candidate can click on the View in drop-down and select
                      the desired language.
                    </li>

                    <li>
                      One question will be displayed on the screen at a time.
                      You are requested to be aware of the amount of time you
                      spend per question. Do not spend too much time on any
                      question.
                    </li>

                    <li>
                      Each question will have alternatives, out of which only
                      one will be the correct answer.
                    </li>

                    <li>
                      In case of any discrepancy between the English and Hindi
                      versions of a question, the English version will prevail.
                    </li>

                    <li>
                      There will be negative marking of
                      {{ testObj.punishmentPoints }} marks per wrong answer
                      attempted.
                    </li>

                    <li>
                      Candidates will
                      {{ testObj.allowSectionSwitching ? "" : "not" }} be
                      allowed to re-visit the same section again after
                      submission of that particular section.
                    </li>
                  </ol>

                  <ol v-else>
                    <li>
                      प्रश्नपत्र में वस्तुनिष्ठ प्रकार के प्रश्न निम्नानुसार
                      हैं:

                      <!-- Bordered Table -->
                      <div
                        class="text-center my-9 mx-2 mx-md-12"
                        style="border: 1px black solid"
                      >
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center black--text">Number</th>
                                <th class="text-center black--text">
                                  Section Name
                                </th>
                                <th class="text-center black--text">
                                  No. of Questions
                                </th>
                                <th class="text-center black--text">Marks</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(section, i) in testObj.sections"
                                :key="i + '_hin'"
                              >
                                <td>{{ i + 1 }}</td>
                                <td>{{ section }}</td>
                                <td>
                                  {{
                                    testObj.selectedQuestions[section].length
                                  }}
                                </td>
                                <td>
                                  {{
                                    testObj.selectedQuestions[section].length *
                                    testObj.rewardPoints
                                  }}
                                </td>
                              </tr>
                              <tr class="font-weight-bold">
                                <td></td>
                                <td>Total</td>
                                <td>{{ testObj.questionCount }}</td>
                                <td>
                                  {{
                                    testObj.questionCount * testObj.rewardPoints
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </li>

                    <li>
                      आपको प्रश्नों को हल करने के लिए
                      <b>{{ testObj.testDuration }}</b> मिनट का समय दिया जाएगा।
                    </li>

                    <li>
                      प्रश्न
                      {{
                        testObj.isBilingual
                          ? "अंग्रेजी और हिंदी"
                          : testObj.onlySecondaryLang
                          ? "हिंदी"
                          : "अंग्रेजी"
                      }}
                      में उपलब्ध होंगे। दिए गए प्रश्न को दूसरी भाषा में देखने के
                      लिए, उम्मीदवार ड्रापडाउन में View in पर क्लिक कर वांछित
                      भाषा चुन सकता है।
                    </li>

                    <li>
                      स्क्रीन पर एक समय में केवल एक प्रश्न दिखाई पड़ेगा। आपसे
                      अनुरोध है कि प्रत्येक प्रश्न पर दिए गए समय का ध्यान रखें ।
                      किसी भी प्रश्न को अत्यधिक समय मत दीजिए।
                    </li>

                    <li>
                      प्रत्येक प्रश्न के लिए विकल्प होंगे, जिनमें से केवल एक सही
                      उत्तर होगा।
                    </li>

                    <li>
                      एक प्रश्न के अंग्रेजी और हिंदी संस्करण के बीच कोई विसंगति
                      के मामले में, अंग्रेजी संस्करण मान्य होगा।
                    </li>

                    <li>
                      गलत जवाब के प्रति {{ testObj.punishmentPoints }} अंकों का
                      नकारात्मक अंकन होगा।
                    </li>

                    <li>
                      उम्मीदवारों को उस विशेष समूह को जमा करने के बाद फिर से उसी
                      समूह को फिर से देखने की अनुमति
                      {{ testObj.allowSectionSwitching ? "" : "नहीं" }} दी जाएगी
                      |
                    </li>
                  </ol>
                </v-col>

                <v-col v-if="testObj.additionalInstructions" cols="12" sm="12">
                  <v-divider class="mb-4"></v-divider>

                  <div class="grey--text text--darken-3 text-h6 pl-3">
                    Additional Instructions:
                  </div>

                  <div
                    class="ma-6 htmlFromEditor"
                    v-html="testObj.additionalInstructions"
                  ></div>
                </v-col>
              </v-row>
            </v-card-text>

            <!-- Footer -->
            <v-footer v-if="!modalView" class="pa-2">
              <v-form
                v-model="valid"
                @submit.prevent="startTest"
                ref="startTestForm"
              >
                <v-container fluid>
                  <!-- Choose Test Default Language and Consent Checkbox -->
                  <v-row no-gutters>
                    <!-- Label -->
                    <v-col
                      cols="12"
                      md="3"
                      class="d-flex justify-start align-center"
                    >
                      <span class="pr-2 mt-n2 mb-1 mb-md-0 mt-md-n7 text-left">
                        Choose your default language:
                      </span>
                    </v-col>

                    <!-- Test Lang Select -->
                    <v-col
                      cols="12"
                      md="2"
                      class="d-flex justify-end align-center ml-0 ml-md-n4"
                    >
                      <v-select
                        v-model="testLangLocal"
                        @change="setTestLang"
                        :items="testLangs"
                        item-text="text"
                        item-value="value"
                        :disabled="loading || superLoading"
                        :rules="requiredRules"
                        dense
                        outlined
                        required
                      ></v-select>
                    </v-col>

                    <!-- Red Text -->
                    <v-col cols="12" sm="12">
                      <small class="red--text mt-n4">
                        Please note all questions will appear in your default
                        language. This language can be changed for a particular
                        question later on.
                      </small>
                    </v-col>

                    <!-- Consent Check box -->
                    <v-col cols="12" sm="12">
                      <v-checkbox
                        v-model="consentCheckbox"
                        :rules="requiredRules"
                        label="I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare  that I am not in possession of / not wearing / not  carrying any prohibited gadget like mobile phone, bluetooth  devices  etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <!-- Butons Row -->
                  <v-row
                    class="
                      d-flex
                      align-center
                      justify-md-space-between justify-center
                    "
                  >
                    <v-btn
                      @click="setCurrentWindow()"
                      class="my-1 mx-0 mx-md-2"
                      outlined
                      tile
                    >
                      <v-icon class="mr-2">mdi-chevron-left</v-icon> Previous
                    </v-btn>
                    <v-btn
                      @click.prevent="startTest()"
                      :disabled="loading || superLoading || !valid"
                      class="my-1 mx-0 mx-md-2"
                      color="success"
                      tile
                    >
                      I am Ready to begin
                    </v-btn>

                    <div class="pa-5"></div>
                  </v-row>
                </v-container>
              </v-form>
            </v-footer>
          </v-card>
        </v-col>

        <!-- Right Side User Data (hide on mobile) -->
        <v-col
          v-if="!$vuetify.breakpoint.mobile && !modalView"
          cols="12"
          sm="12"
          md="3"
          class="d-flex align-center flex-column my-3"
        >
          <v-avatar v-if="userData.photoURL" size="150" class="mb-6">
            <v-img width="150" height="150" :src="userData.photoURL"></v-img>
          </v-avatar>

          <v-icon v-else size="150" class="mb-6">mdi-account-circle</v-icon>

          <span class="text-h6">
            {{ userData.fullName }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "OtherInstructions",
  props: [
    "superLoading",
    "instructionsLang",
    "testObj",
    "userData",
    "modalView",
  ],
  computed: {
    testLangs() {
      return this.testObj.isBilingual
        ? [
            {
              text: "English",
              value: "en",
            },
            {
              text: "Hindi",
              value: "hi",
            },
          ]
        : this.testObj.onlySecondaryLang
        ? [
            {
              text: "Hindi",
              value: "hi",
            },
          ]
        : [
            {
              text: "English",
              value: "en",
            },
          ];
    },
  },
  data: () => ({
    loading: true,
    valid: false,
    error: "",
    instructionsLangLocal: "",
    testLangLocal: "",
    langs: [
      {
        text: "English",
        value: "en",
      },
      {
        text: "Hindi",
        value: "hi",
      },
    ],
    requiredRules: [(value) => !!value || "Required."],

    consentCheckbox: false,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    setCurrentWindow() {
      this.$emit("setCurrentWindow", "general_instructions");
    },
    setInstructionsLang(val) {
      this.$emit("setInstructionsLang", val);
    },
    setTestLang(val) {
      this.$emit("setTestLang", val);
    },
    startTest() {
      //do checks
      if (this.$refs.startTestForm.validate()) {
        this.$emit("startTest");
      }
    },
  },

  mounted() {
    this.loading = false;
    this.instructionsLangLocal = this.instructionsLang;
  },
};
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

ol > li {
  margin-top: 0.5rem;
}

.icon-legend-div > div {
  margin-top: 0.5rem;
}
</style>