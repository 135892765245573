<template>
  <div justify="center" id="testInterface" class="mx-0 px-0 fill-height">
    <ConfirmationDialog
      :superLoading="loading"
      :dialog="confirmationDialog"
      :dialogType="confirmationDialogType"
      :error="error"
      @setDialog="setConfirmationDialog"
      @confirmSubmit="confirmSubmitDialog"
    />

    <v-container fluid>
      <v-row v-if="selectedSection" align="center" justify="center">
        <v-card
          elevation="2"
          width="100%"
          class="mt-0 mt-md-2 mb-2 mx-0 mx-md-3"
        >
          <v-card-title class="py-2">{{ testObj.testName }}</v-card-title>

          <!-- Sections Bar -->
          <SectionsAndTimeBar
            :superLoading="loading"
            :userData="userData"
            :sections="testObj.sections"
            :allowSwitch="testObj.allowSectionSwitching"
            :selectedSection="selectedSection"
            :timeLeftText="timeLeftText"
            @setSelectedSection="setSelectedSection"
          />

          <!-- Question type and marking and Language Select -->
          <MarkingAndLangSelect
            :superLoading="loading"
            :punishmentPoints="testObj.punishmentPoints"
            :rewardPoints="testObj.rewardPoints"
            :testLangs="testLangs"
            :selectedTestLang="testLangLocal"
            @setSelectedTestLang="setSelectedTestLang"
          />
        </v-card>

        <!-- Question Column -->
        <v-col v-if="selectedQuestion" cols="12" sm="12" class="ma-0 pa-0">
          <QuestionInterface
            :superLoading="loading"
            :testLang="testLangLocal"
            :question="selectedQuestion"
            :questionIndex="selectedQuestionIndex"
            :selectedAnswer="selectedAnswers[selectedQuestion.id]"
            @markForReviewAndNext="markForReviewAndNext"
            @clearResponse="clearResponse"
            @saveAndNext="saveAndNext"
          />
        </v-col>

        <!-- Drawer Toggle Button -->
        <v-btn
          id="drawer-toggle-btn"
          :class="
            drawer && $vuetify.breakpoint.mobile
              ? 'grey darken-2 pr-8 py-4 drawer-close-button rounded-l-xl'
              : 'grey darken-2 drawer-open-button rounded-l-xl py-4'
          "
          elevation="1"
          absolute
          small
          @click.prevent="drawer = !drawer"
        >
          <v-icon color="white">
            {{ drawer ? "mdi-chevron-right" : "mdi-chevron-left" }}
          </v-icon>
        </v-btn>

        <TestSidebar
          :drawer="drawer"
          :userData="userData"
          :sectionName="selectedSection"
          :questionsArr="testObj.selectedQuestions[selectedSection]"
          :questionState="questionState"
          @setDrawer="setDrawer"
          @questionClicked="questionClicked"
          @submitTest="openConfirmationDialog"
        />
      </v-row>
      <v-row v-else>
        <v-col><h1 class="text-h6">Loading... Test Data</h1></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

import TestSidebar from "@/components/Home/Test/TestInterfaceComps/TestSidebar.vue";
import SectionsAndTimeBar from "@/components/Home/Test/TestInterfaceComps/SectionsAndTimeBar.vue";
import MarkingAndLangSelect from "@/components/Home/Test/TestInterfaceComps/MarkingAndLangSelect.vue";
import QuestionInterface from "@/components/Home/Test/TestInterfaceComps/QuestionInterface.vue";
import ConfirmationDialog from "@/components/Home/Test/TestInterfaceComps/ConfirmationDialog.vue";

export default {
  name: "TestInterface",
  props: [
    "superLoading",
    "testLang",
    "testObj",
    "allQuestions",
    "userData",
    "reAttempt",
    "usePersistedState",
  ],
  computed: {
    testLangs() {
      return this.testObj.isBilingual
        ? [
            {
              text: "English",
              value: "en",
            },
            {
              text: "Hindi",
              value: "hi",
            },
          ]
        : this.testObj.onlySecondaryLang
        ? [
            {
              text: "Hindi",
              value: "hi",
            },
          ]
        : [
            {
              text: "English",
              value: "en",
            },
          ];
    },

    selectedQuestionIndex() {
      if (!this.selectedQuestion) {
        return 0;
      }

      return this.testObj.selectedQuestions[this.selectedSection].indexOf(
        this.selectedQuestion.id
      );
    },

    timeLeftText() {
      if (!this.timeLeft) {
        return "00.00.00";
      }
      return this.timeLeft;
    },
  },

  components: {
    TestSidebar,
    SectionsAndTimeBar,
    MarkingAndLangSelect,
    QuestionInterface,
    ConfirmationDialog,
  },

  data: () => ({
    loading: true,
    valid: false,
    error: "s",
    drawer: true,
    testLangLocal: "",
    questionState: {
      //question: state
    },
    selectedSection: "",
    selectedQuestion: "",
    selectedAnswers: {},
    confirmationDialog: false,
    confirmationDialogType: "", //'section', 'submit', 'submit_info', 'error'
    //possibleStates: {
    //   "not-visited": 1,
    //   "not-answered": 2,
    //   answered: 3,
    //   review: 4,
    //   "review-marked": 5,
    // },

    //section timer data
    sectionSessionStart: null,
    sectionTimes: {
      //sectionName: dayjsObj -- when section is switched to
      //sectionName: [oldVal, oldVal2, (dayjsObj-currentDayjsObj)] -- when section is left
    },

    //test timer data
    testWillEndAt: null,
    timeInterval: null,
    timeLeft: null,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    setCurrentWindow() {
      this.$emit("setCurrentWindow", "result");
    },

    setTestLang(val) {
      this.$emit("setTestLang", val);
    },

    setDrawer(value) {
      this.drawer = value;
    },

    setConfirmationDialog(value) {
      this.confirmationDialog = value;
      this.confirmationDialogType = "";
    },

    startTestTimer() {
      let testWillEndAt; 
      if(this.usePersistedState){
        testWillEndAt = dayjs(this.getActiveTestData().testWillEndAt);
      }else{
        testWillEndAt = dayjs().add(this.testObj.testDuration, "minutes");
      }
      this.testWillEndAt = testWillEndAt;
      this.setToActiveTestData("testWillEndAt", testWillEndAt);

      this.timeInterval = setInterval(() => {
        var currMoment = dayjs();
        var timeLeft = this.testWillEndAt.diff(currMoment);
        this.timeLeft = dayjs.utc(timeLeft).format("HH:mm:ss");
        if (currMoment.isSameOrAfter(this.testWillEndAt)) {
          clearInterval(this.timeInterval);
          this.timeLeft = null;
          this.openConfirmationDialog("submit_info");
          this.submitTest();
          return;
        }
      }, 1000);
    },

    setSelectedSection(sectionName) {
      const oldSelectedSec = this.selectedSection;
      const newSelectedSec = sectionName;

      const currentTimeObj = dayjs();
      if (oldSelectedSec) {
        // calculate the time spent (in seconds) and add it to the array
        const secsSpent = currentTimeObj.diff(
          this.sectionSessionStart,
          "second"
        );

        var sectionTimesArr = this.sectionTimes[oldSelectedSec];
        sectionTimesArr.push(secsSpent);
        this.$set(this.sectionTimes, oldSelectedSec, sectionTimesArr);
        this.setToActiveTestData("sectionTimes", this.sectionTimes);
      }

      if (newSelectedSec) {
        //create section array if it does not exist
        if (!(newSelectedSec in this.sectionTimes)) {
          this.$set(this.sectionTimes, newSelectedSec, []);
          this.setToActiveTestData("sectionTimes", this.sectionTimes);
        }

        //set refrence time for calculation later
        this.sectionSessionStart = currentTimeObj;
        this.setToActiveTestData("sectionSessionStart", this.sectionSessionStart);
      }

      this.selectedSection = sectionName;
      this.setToActiveTestData("selectedSection", this.selectedSection);

      const questionID = this.testObj.selectedQuestions[sectionName][0];
      var state = this.questionState[questionID];

      //if state is == 'not-visited' then and only then update the state
      if (!(questionID in this.questionState) || state == "not-visited") {
        state = "not-answered";
      }

      // Set first question of the new section
      this.setSelectedQuestion({
        questionID,
        state,
      });
    },

    setQuestionState(stateData) {
      //stateData = {questionID, state}
      this.$set(this.questionState, stateData.questionID, stateData.state);
      this.setToActiveTestData("questionState", this.questionState);
    },

    setSelectedQuestion(stateData) {
      //stateData = {questionID, state}
      this.selectedQuestion = this.allQuestions[stateData.questionID];
      this.setToActiveTestData("selectedQuestion", this.selectedQuestion);
      this.setQuestionState(stateData);
    },

    setSelectedTestLang(val) {
      this.testLangLocal = val;
      this.setToActiveTestData("testLangLocal", this.testLangLocal);
      this.setToActiveTestData("testLang", this.testLangLocal);
    },

    questionClicked(questionID) {
      var state = this.questionState[questionID];
      //if state is == 'not-visited' then and only then update the state
      if (!(questionID in this.questionState) || state == "not-visited") {
        state = "not-answered";
      }

      this.setSelectedQuestion({ questionID, state });
    },

    markForReviewAndNext(answer) {
      if (answer) {
        this.$set(this.selectedAnswers, this.selectedQuestion.id, answer);
        this.setToActiveTestData("selectedAnswers", this.selectedAnswers);

        this.setQuestionState({
          questionID: this.selectedQuestion.id,
          state: "review-marked",
        });
      } else {
        this.setQuestionState({
          questionID: this.selectedQuestion.id,
          state: "review",
        });
      }

      this.initiateNextQuestion();
    },

    clearResponse() {
      this.$delete(this.selectedAnswers, this.selectedQuestion.id);
      this.setToActiveTestData("selectedAnswers", this.selectedAnswers);

      this.setQuestionState({
        questionID: this.selectedQuestion.id,
        state: "not-answered",
      });
    },

    saveAndNext(answer) {
      if (answer) {
        this.$set(this.selectedAnswers, this.selectedQuestion.id, answer);
        this.setToActiveTestData("selectedAnswers", this.selectedAnswers);

        //set state to answered
        this.setQuestionState({
          questionID: this.selectedQuestion.id,
          state: "answered",
        });
      }
      // set next question
      this.initiateNextQuestion();
    },

    initiateNextQuestion() {
      //check if this is not the last question of the section: then set next
      if (
        this.selectedQuestionIndex + 1 <
        this.testObj.selectedQuestions[this.selectedSection].length
      ) {
        const questionID =
          this.testObj.selectedQuestions[this.selectedSection][
            this.selectedQuestionIndex + 1
          ];

        var state = this.questionState[questionID];

        //if state is == 'not-visited' then and only then update the state
        if (!(questionID in this.questionState) || state == "not-visited") {
          state = "not-answered";
        }

        this.setSelectedQuestion({
          questionID,
          state,
        });
      }
      //else if: check if this is the not last section: then set next section
      else if (
        this.testObj.sections.indexOf(this.selectedSection) + 1 <
        this.testObj.sections.length
      ) {
        // if section switching is allowed: set next section
        if (this.testObj.allowSectionSwitching) {
          this.setSelectedSection(
            this.testObj.sections[
              this.testObj.sections.indexOf(this.selectedSection) + 1
            ]
          );

        }
        //else: show section switch dialog
        else {
          this.openConfirmationDialog("section");
        }
      }
      //else: submit test
      else {
        this.openConfirmationDialog("submit");
      }
    },

    openConfirmationDialog(dialogType) {
      this.confirmationDialogType = dialogType;
      this.confirmationDialog = true;
    },

    confirmSubmitDialog(dialogType) {
      this.loading = true;

      switch (dialogType) {
        case "section":
          this.setSelectedSection(
            this.testObj.sections[
              this.testObj.sections.indexOf(this.selectedSection) + 1
            ]
          );
          break;
        case "submit":
          this.submitTest();
          break;
      }
      this.confirmationDialog = false;
      this.confirmationDialogType = "";
      this.loading = false;
    },

    calculateScore(selectedAnswersObj, questionStateObj) {
      var score = 0;

      for (let questionID of Object.keys(selectedAnswersObj)) {
        var selectedAnswer = selectedAnswersObj[questionID];
        var questionData = this.allQuestions[questionID];

        // evaluate iff state is "answered" and not just 'review-marked'
        if (questionStateObj[questionID] == "answered") {
          if (selectedAnswer == questionData.correctAnswer) {
            score += this.testObj.rewardPoints;
          } else {
            score -= this.testObj.punishmentPoints;
          }
        }
      }

      return score;
    },

    submitTest() {
      this.loading = true;

      // Calculate and store last selected section's time spent
      const oldSelectedSec = this.selectedSection;
      const secsSpent = dayjs().diff(this.sectionSessionStart, "second");

      var sectionTimesArr = this.sectionTimes[oldSelectedSec];
      sectionTimesArr.push(secsSpent);
      this.$set(this.sectionTimes, oldSelectedSec, sectionTimesArr);
      this.setToActiveTestData("sectionTimes", this.sectionTimes);

      clearInterval(this.timeInterval);

      var payload = {
        submittedAt: new Date(),
        testID: this.testObj.id,
        studentUID: this.userData.uid,
        studentData: {
          name: this.userData.fullName,
          course: this.userData.selectedCourse ?? "NA",
        },
        questionState: this.questionState,
        selectedAnswers: this.selectedAnswers,
        score: 0,
        timeLeft: this.timeLeft,
        sectionTimes: this.sectionTimes,
      };

      payload.score = this.calculateScore(
        payload.selectedAnswers,
        payload.questionState
      );

      if (this.reAttempt) {
        payload.reAttempt = true;
        this.$emit("showResult", payload);
      } else {
        this.$store
          .dispatch("submitTestResult", payload)
          .then(() => {
            this.$emit("showResult", payload);
            this.loading = false;
          })
          .catch((err) => {
            this.error = err;
            console.log("err: ", err);
            this.openConfirmationDialog("error");
          });
      }
    },

    setToActiveTestData(key, data){
      this.$store.dispatch("setToActiveTestData", {key, data});
    },
    
    getActiveTestData(){
      return this.$store.getters.activeTestData;
    }
  },

  mounted() {
    this.testLangLocal = this.testLang;

    // if persisted state, pre-populate state
    if(this.usePersistedState){
      const activeTestData = this.getActiveTestData();
      this.questionState =  activeTestData.questionState;
      this.sectionSessionStart = activeTestData.sectionSessionStart;
      this.sectionTimes = activeTestData.sectionTimes;
      this.selectedAnswers = activeTestData.selectedAnswers;
      this.selectedQuestion = activeTestData.selectedQuestion;
      this.selectedSection = activeTestData.selectedSection;
    }else{
      //assign first section on mount
      this.setSelectedSection(this.testObj.sections[0]);
    }

    //start Timer
    this.startTestTimer();


    this.loading = false;
  },
};
</script>

<style scoped>
html,
body {
  overflow-x: hidden !important;
}

#drawer-toggle-btn {
  top: 56vh;
  right: 223px;
}

.drawer-close-button {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  right: 223px !important;
}

.drawer-open-button {
  right: 0 !important;
}

#testInterface {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
</style>