<template>
  <v-dialog v-model="dialog" max-width="390" persistent>
    <v-card :loading="loading || superLoading">
      <v-card-title class="text-h6"> Notice! </v-card-title>

      <v-card-text>
        <div v-if="dialogType == 'submit'">
          Are you sure you want to submit the test?
        </div>
        <div v-else-if="dialogType == 'section'">
          Are you sure you want to submit the section and proceed?
        </div>
        <div v-else-if="dialogType == 'submit_info'">
          The test is being submitted automatically.
        </div>
        <div v-else-if="dialogType == 'error'">
          {{ error }}
        </div>
      </v-card-text>

      <v-card-actions v-if="dialogType != 'submit_info'">
        <v-spacer></v-spacer>

        <v-btn
          @click="dialogComputed = false"
          :disabled="superLoading || loading"
          text
          small
        >
          Cancel
        </v-btn>

        <v-btn
          :loading="superLoading || loading"
          color="primary white--text"
          @click="confirmSubmit()"
          small
        >
          Proceed
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  name: "ConfirmationDialog",
  props: ["superLoading", "dialog", "dialogType", "error"],
  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("setDialog", value);
      },
    },
  },
  data: () => ({
    loading: true,
  }),
  methods: {
    confirmSubmit() {
      this.$emit("confirmSubmit", this.dialogType);
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>