<template>
  <v-container fluid id="markingAndLangSelect" class="white px-6 pt-5 pb-0">
    <!-- Marking Scheme -->
    <v-row align="center" class="grey--text text--darken-4 justify-center">
      <small class="mx-1 hidden-sm-and-down">Question Type: MCQ </small>
      <v-spacer class="mx-1 hidden-sm-and-down"></v-spacer>
      <small>
        {{
          !$vuetify.breakpoint.mobile
            ? "Marks for correct answer:"
            : "Correct Marks:"
        }}
        <v-chip class="green darken-3 white--text mr-1" pill small>
          {{ rewardPoints }}
        </v-chip>
      </small>
      <small>
        &nbsp;&nbsp;Negative marks:
        <v-chip class="red darken-3 white--text" pill small>
          {{ punishmentPoints }}
        </v-chip>
      </small>
    </v-row>

    <v-row>
      <v-divider class="mt-4 mb-n2"></v-divider>
    </v-row>

    <!-- View In Language Select -->
    <v-row
      align="center"
      justify="end"
      class="grey--text text--darken-4 mt-7 mt-md-4"
      no-gutters
    >
      <v-col
        v-if="!$vuetify.breakpoint.mobile"
        cols="2"
        sm="1"
        class="justify-end align-center pa-0 ma-0 pt-4 mb-n4 d-none d-md-flex"
      >
        <span class="mr-2 mr-md-n6 mt-n6">View In:</span>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        :sm="$vuetify.breakpoint.mobile ? 12 : 2"
        class="
          d-flex
          justify-end
          align-center
          pa-0
          px-0 px-md-7
          ma-0
          mr-0 mr-md-n7
          pt-0 pt-md-4
          mb-n4
        "
      >
        <v-select
          id="langSelect"
          label="Language"
          v-model="testLang"
          :items="testLangs"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
export default {
  name: "MarkingAndLangSelect",
  props: [
    "superLoading",
    "punishmentPoints",
    "rewardPoints",
    "testLangs",
    "selectedTestLang",
  ],
  computed: {
    testLang: {
      // getter
      get: function () {
        return this.selectedTestLang;
      },
      // setter
      set: function (val) {
        this.$emit("setSelectedTestLang", val);
      },
    },
  },
  data: () => ({
    dialog: false,
    instructionsLang: "en",
  }),
  components: {},
  methods: {
    setInstructionsLang(val) {
      this.instructionsLang = val;
    },
    setSelectedSection(sectionName) {
      if (this.allowSwitch) {
        this.$emit("setSelectedSection", sectionName);
      }
    },
  },
};
</script>