<template>
  <div justify="center" id="generalInstructions" class="mx-0 px-0 fill-height">
    <v-container fluid>
      <!-- Top bar -->
      <v-row
        v-if="!modalView"
        style="min-height: 6.5vh"
        class="deep-purple white--text d-flex align-center pl-3"
      >
        {{ testObj.testName }}
      </v-row>

      <!-- Page Content -->
      <v-row>
        <!-- Left Side User Data (Only on mobile) -->
        <v-col
          v-if="$vuetify.breakpoint.mobile && !modalView"
          cols="12"
          sm="12"
          md="3"
          class="d-flex align-center flex-column my-3"
        >
          <v-avatar v-if="userData.photoURL" size="150" class="mb-6">
            <v-img width="150" height="150" :src="userData.photoURL"></v-img>
          </v-avatar>

          <v-icon v-else size="150" class="mb-6">mdi-account-circle</v-icon>

          <span class="text-h6">
            {{ userData.fullName }}
          </span>
        </v-col>

        <!-- Left Side Instructions (Right on Mobile) -->
        <v-col cols="12" sm="12" :md="modalView ? 12 : 9" class="ma-0 pa-0">
          <v-card height="93vh" class="d-flex flex-column" tile elevation="0">
            <!-- 'Instructions' Bar -->
            <v-system-bar
              v-if="!modalView"
              class="deep-purple lighten-4 pa-0"
              height="105"
            >
              <span class="grey--text text--darken-3 text-h6 pl-3">
                Instructions
              </span>
            </v-system-bar>

            <v-card-text class="black--text">
              <!-- Instructions Language Select Row -->
              <v-row justify="end" align="center" no-gutters>
                <v-col
                  cols="12"
                  md="3"
                  class="d-flex justify-md-end justify-start align-center"
                >
                  <span class="pr-2 mt-n2 mb-1 mb-md-0 mt-md-n7 text-right">
                    View In:
                  </span>
                </v-col>
                <v-col cols="12" md="2" class="d-flex justify-end align-center">
                  <v-select
                    v-model="instructionsLangLocal"
                    @change="setInstructionsLang"
                    :items="langs"
                    item-text="text"
                    item-value="value"
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>

              <!-- 'Please read instructions carefully' -->
              <v-row justify="center">
                <p
                  v-if="instructionsLangLocal == 'en'"
                  class="text-body-2 text-center"
                >
                  Please read the instructions carefully.
                </p>
                <p v-else class="text-body-2 text-center">
                  कृपया निम्नलिखित निर्देशों को ध्यान से पढ़ें
                </p>
              </v-row>

              <!-- Instructions Row -->
              <v-row justify="start" class="mx-1 mx-md-4">
                <v-col cols="12" sm="12">
                  <u class="text-body-2">
                    <b v-if="instructionsLangLocal == 'en'"
                      >General Instructions:</b
                    >
                    <b v-else>सामान्य अनुदेश</b>
                  </u>
                </v-col>

                <!-- Lists -->
                <v-col cols="12" sm="12">
                  <ol v-if="instructionsLangLocal == 'en'">
                    <li>
                      Total duration of examination is
                      {{ testObj.testDuration }} minutes.
                    </li>
                    <li>
                      The clock will be set at the server. The countdown timer
                      in the top right corner of screen will display the
                      remaining time available for you to complete the
                      examination. When the timer reaches zero, the examination
                      will end by itself. You will not be required to end or
                      submit your examination.
                    </li>
                    <li>
                      The Question Palette displayed on the right side of screen
                      will show the status of each question using one of the
                      following symbols:

                      <!-- Icon Legend Div -->
                      <div class="pl-2 my-2 icon-legend-div">
                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-not-visited">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            You have not visited the question yet.
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-not-answered">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            You have not answered the question.
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-answered">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            You have answered the question.
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-review">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            You have NOT answered the question, but have marked
                            the question for review.
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-review-marked">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            The question(s) "Marked for Review" will be not be
                            considered for evaluation. Hence, no marks will be
                            allocated for the same.
                          </span>
                        </div>
                      </div>

                      The Marked for Review status for a question simply
                      indicates that you would like to look at that question
                      again.
                    </li>

                    <li>
                      You can click on the "&#62;" arrow which appears to the
                      left of question palette to collapse the question palette
                      thereby maximizing the question window. To view the
                      question palette again, you can click on "&#60; " which
                      appears on the right side of question window.
                    </li>

                    <li>
                      You can click on your "Profile" image on top right corner
                      of your screen to change the language during the exam for
                      entire question paper. On clicking of Profile image you
                      will get a drop-down to change the question content to the
                      desired language.
                    </li>

                    <li>
                      <div class="d-flex align-center">
                        You can click on
                        <v-img
                          class="flex-grow-0 flex-shrink-0 mx-1"
                          height="28"
                          width="28"
                          src="@/assets/home/test/Down.png"
                        ></v-img>
                        to navigate to the bottom and
                        <v-img
                          class="flex-grow-0 flex-shrink-0 mx-1"
                          height="28"
                          width="28"
                          src="@/assets/home/test/Up.png"
                        ></v-img
                        >to navigate to the top of the question area, without
                        scrolling.
                      </div>
                    </li>
                  </ol>

                  <ol v-else>
                    <li>
                      सभी प्रश्नों को हल करने के लिए आपको
                      {{ testObj.testDuration }} मिनट का समय दिया जाएगा।
                    </li>
                    <li>
                      सर्वर पर घड़ी लगाई गई है तथा आपकी स्क्रीन के दाहिने कोने
                      में शीर्ष पर काउंटडाउन टाइमर में आपके लिए परीक्षा समाप्त
                      करने के लिए शेष समय प्रदर्शित होगा। परीक्षा समय समाप्त
                      होने पर, आपको अपनी परीक्षा बंद या जमा करने की जरूरत नहीं
                      है । यह स्वतः बंद या जमा हो जाएगी।
                    </li>

                    <li>
                      स्क्रीन के दाहिने कोने पर प्रश्न पैलेट, प्रत्येक प्रश्न के
                      लिए निम्न में से कोई एक स्थिति प्रकट करता है:

                      <!-- Icon Legend Div -->
                      <div class="pl-2 my-2 icon-legend-div">
                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-not-visited">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            आप अभी तक प्रश्न पर नहीं गए हैं।
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-not-answered">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            आपने प्रश्न का उत्तर नहीं दिया है।
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-answered">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            आप प्रश्न का उत्तर दे चुके हैं।
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-review">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            आपने प्रश्न का उत्तर नहीं दिया है पर प्रश्न को
                            पुनर्विचार के लिए चिन्हित किया है।
                          </span>
                        </div>

                        <div class="d-flex justify-start align-center">
                          <div class="legend-icon icon-review-marked">
                            <span class="text-no-wrap"> 1 </span>
                          </div>
                          <span class="ml-2">
                            प्रश्न "समीक्षा के लिए चिह्नित" की जांच नहीं की
                            जाएगी, इसलिए अंक आवंटित नहीं किए जाएंगे।
                          </span>
                        </div>
                      </div>

                      पुनर्विचार के लिए चिह्नित (Marked for Review) स्थिति
                      सामान्यतः अनुस्मारक के रूप में कार्य करती है जिसे आपने
                      प्रश्न को दुबारा देखने के लिए सेट किया है।
                    </li>

                    <li>
                      आप प्रश्न पैलेट को छुपाने के लिए, "&#62;" चिन्ह पर क्लिक
                      कर सकते है, जो प्रश्न पैलेट के बाईं ओर दिखाई देता है,
                      जिससे प्रश्न विंडो सामने आ जाएगा. प्रश्न पैलेट को फिर से
                      देखने के लिए, " &#60; " चिन्ह पर क्लिक कीजिए जो प्रश्न
                      विंडो के दाईं ओर दिखाई देता है।
                    </li>

                    <li>
                      सम्पूर्ण प्रश्नपत्र की भाषा को परिवर्तित करने के लिए आप को
                      अपने स्क्रीन के ऊपरी दाहिने सिरे पर स्थित प्रोफाइल इमेज पर
                      क्लिक करना होगा। प्रोफाइल इमेज को क्लिक करने पर आपको
                      प्रश्न के अंतर्वस्तु को इच्छित भाषा में परिवर्तित करने के
                      लिए ड्राप-डाउन मिलेगा ।
                    </li>

                    <li>
                      <div class="d-flex align-center">
                        आपको अपने स्क्रीन के निचले हिस्से को स्क्रॉलिंग के बिना
                        नेविगेट करने के लिए
                        <v-img
                          class="flex-grow-0 flex-shrink-0 mx-1"
                          height="28"
                          width="28"
                          src="@/assets/home/test/Down.png"
                        ></v-img>
                        और ऊपरी हिस्से को नेविगेट करने के लिए
                        <v-img
                          class="flex-grow-0 flex-shrink-0 mx-1"
                          height="28"
                          width="28"
                          src="@/assets/home/test/Up.png"
                        ></v-img
                        >पर क्लिक करना होगा ।
                      </div>
                    </li>
                  </ol>
                </v-col>

                <v-col cols="12" sm="12" class="mt-1 mb-n2">
                  <u class="text-body-2">
                    <b v-if="instructionsLangLocal == 'en'"
                      >Navigating to a Question:</b
                    >
                    <b v-else>किसी प्रश्न पर जाना :</b>
                  </u>
                </v-col>

                <!-- Lists start: 7 -->
                <v-col cols="12" sm="12">
                  <ol v-if="instructionsLangLocal == 'en'" start="7">
                    <li>To answer a question, do the following:</li>

                    <ol type="a">
                      <li>
                        Click on the question number in the Question Palette at
                        the right of your screen to go to that numbered question
                        directly. Note that using this option does NOT save your
                        answer to the current question.
                      </li>
                      <li>
                        Click on <b>Save &amp; Next</b> to save your answer for
                        the current question and then go to the next question.
                      </li>

                      <li>
                        Click on <b>Mark for Review &amp; Next</b> to save your
                        answer for the current question, mark it for review, and
                        then go to the next question.
                      </li>
                    </ol>
                  </ol>

                  <ol v-else start="7">
                    <li>
                      उत्तर देने हेतु कोई प्रश्न चुनने के लिए, आप निम्न में से
                      कोई एक कार्य कर सकते हैं:
                    </li>

                    <ol type="a">
                      <li>
                        स्क्रीन के दायीं ओर प्रश्न पैलेट में प्रश्न पर सीधे जाने
                        के लिए प्रश्न संख्या पर क्लिक करें। ध्यान दें कि इस
                        विकल्प का प्रयोग करने से मौजूदा प्रश्न के लिए आपका उत्तर
                        सुरक्षित नहीं होता है।
                      </li>
                      <li>
                        वर्तमान प्रश्न का उत्तर सुरक्षित करने के लिए और क्रम में
                        अगले प्रश्न पर जाने के लिए <b>Save &amp; Next</b> पर
                        क्लिक करें।
                      </li>

                      <li>
                        वर्तमान प्रश्न का उत्तर सुरक्षित करने के लिए, पुनर्विचार
                        के लिए चिह्नित करने और क्रम में अगले प्रश्न पर जाने के
                        लिए <b>Mark for Review &amp; Next</b> पर क्लिक करें।
                      </li>
                    </ol>
                  </ol>
                </v-col>

                <v-col cols="12" sm="12" class="mt-1 mb-n2">
                  <u class="text-body-2">
                    <b v-if="instructionsLangLocal == 'en'"
                      >Answering a Question :</b
                    >
                    <b v-else> प्रश्नों का उत्तर देना : </b>
                  </u>
                </v-col>

                <!-- Lists start: 8 -->
                <v-col cols="12" sm="12">
                  <ol v-if="instructionsLangLocal == 'en'" start="8">
                    <li>
                      Procedure for answering a multiple choice type question:
                    </li>

                    <ol type="a">
                      <li>
                        To select your answer, click on the button of one of the
                        options
                      </li>
                      <li>
                        To deselect your chosen answer, click on the button of
                        the chosen option again or click on the
                        <b>Clear Response</b>
                        button
                      </li>

                      <li>
                        To change your chosen answer, click on the button of
                        another option
                      </li>

                      <li>
                        To save your answer, you MUST click on the
                        <b>Save &amp; Next</b> button
                      </li>

                      <li>
                        To mark the question for review, click on the
                        <b>Mark for Review &amp; Next</b> button.
                      </li>
                    </ol>

                    <li>
                      To change your answer to a question that has already been
                      answered, first select that question for answering and
                      then follow the procedure for answering that type of
                      question.
                    </li>
                  </ol>
                  <ol v-else start="8">
                    <li>बहुविकल्प प्रकार प्रश्न के लिए:</li>

                    <ol type="a">
                      <li>
                        अपना उत्तर चुनने के लिए, विकल्प के बटनों में से किसी एक
                        पर क्लिक करें।
                      </li>
                      <li>
                        चयनित उत्तर को अचयनित करने के लिए, चयनित विकल्प पर
                        दुबारा क्लिक करें या <b>Clear Response</b> बटन पर क्लिक
                        करें।
                      </li>

                      <li>
                        अपना उत्तर बदलने के लिए, अन्य वांछित विकल्प बटन पर क्लिक
                        करें।
                      </li>

                      <li>
                        अपना उत्तर सुरक्षित करने के लिए, आपको
                        <b>Save &amp; Next</b> पर क्लिक करना जरूरी है।
                      </li>

                      <li>
                        किसी प्रश्न को पुनर्विचार के लिए चिह्नित करने हेतु
                        <b>Mark for Review &amp; Next</b> बटन पर क्लिक करें।
                      </li>
                    </ol>

                    <li>
                      किसी प्रश्न का उत्तर बदलने के लिए, पहले प्रश्न का चयन
                      करें, फिर नए उत्तर विकल्प पर क्लिक करने के बाद
                      <b>Save &amp; Next</b> बटन पर क्लिक करें।
                    </li>
                  </ol>
                </v-col>

                <v-col cols="12" sm="12" class="mt-1 mb-n2">
                  <u class="text-body-2">
                    <b v-if="instructionsLangLocal == 'en'"
                      >Navigating through sections:</b
                    >
                    <b v-else> अनुभागों द्वारा प्रश्न पर जाना: </b>
                  </u>
                </v-col>

                <!-- Lists start: 10-->
                <v-col cols="12" sm="12">
                  <ol v-if="instructionsLangLocal == 'en'" start="10">
                    <li>
                      Sections in this question paper are displayed on the top
                      bar of the screen.The section you are currently viewing is
                      highlighted.
                    </li>

                    <li>
                      After clicking on the Submit button for a particular
                      section,You can view the corresponding section summary and
                      will be able to view next section on Clicking "Yes" on
                      summary.
                    </li>

                    <li>
                      You
                      {{ testObj.allowSectionSwitching ? "can" : "cannot" }}
                      shuffle between sections anytime during the examination.
                    </li>

                    <li>
                      All the sections will be opened in
                      {{
                        testObj.allowSectionSwitching ? "any " : "sequential"
                      }}
                      order and you
                      {{ testObj.allowSectionSwitching ? "will " : "will not" }}
                      be allowed to attempt any section by choice.
                    </li>
                  </ol>

                  <ol v-else start="10">
                    <li>
                      इस प्रश्न पत्र में अनुभाग स्क्रीन के शीर्ष पट्टी पर
                      प्रदर्शित किए जाते हैं । वर्तमान में जो अनुभाग आप देख रहे
                      है वह हाइलाइट किया गया है।
                    </li>

                    <li>
                      एक विशेष अनुभाग के लिए सबमिट करें बटन पर क्लिक करने के
                      बाद, आप इसी अनुभाग सारांश देख सकते हैं और पर क्लिक करें पर
                      अगले अनुभाग देखने के लिए सक्षम हो जाएगा "हाँ" सारांश पर।
                    </li>

                    <li>
                      आप निर्धारित समय के दौरान अपनी सुविधा के अनुसार परीक्षा के
                      दौरान कभी भी वर्गों के बीच फेरबदल नहीं कर सकते।
                    </li>

                    <li>
                      सभी वर्गों अनुक्रमिक क्रम में खोला जाएगा और विकल्प के
                      द्वारा किसी भी अनुभाग का प्रयास करने की अनुमति नहीं दी
                      जाएगी।
                    </li>
                  </ol>
                </v-col>

                <v-col cols="12" sm="12" class="mt-1 mb-n2">
                  <u class="text-body-2">
                    <b v-if="instructionsLangLocal == 'en'"
                      >Instruction for images:</b
                    >
                    <b v-else> छवियों के लिए निर्देश: </b>
                  </u>
                </v-col>

                <!-- Lists start: 14-->
                <v-col cols="12" sm="12">
                  <ol v-if="instructionsLangLocal == 'en'" start="14">
                    <li>
                      To zoom the image provided in the question roll over it.
                    </li>
                  </ol>
                  <ol v-else start="14">
                    <li>
                      प्रश्न में दी गई छवि को झूम करने के लिए उस पर माउस से रोल
                      करें ।
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-card-text>

            <v-footer v-if="!modalView" class="pa-2 d-flex justify-end">
              <v-btn class="mr-4" @click="setCurrentWindow" outlined tile>
                Next <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
            </v-footer>
          </v-card>
        </v-col>

        <!-- Right Side User Data (hide on mobile) -->
        <v-col
          v-if="!$vuetify.breakpoint.mobile && !modalView"
          cols="12"
          sm="12"
          md="3"
          class="d-flex align-center flex-column my-3"
        >
          <v-avatar v-if="userData.photoURL" size="150" class="mb-6">
            <v-img width="150" height="150" :src="userData.photoURL"></v-img>
          </v-avatar>

          <v-icon v-else size="150" class="mb-6">mdi-account-circle</v-icon>

          <span class="text-h6">
            {{ userData.fullName }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "GeneralInstructions",
  props: [
    "superLoading",
    "instructionsLang",
    "testObj",
    "userData",
    "modalView",
  ],

  data: () => ({
    loading: true,
    error: "",
    instructionsLangLocal: "",
    langs: [
      {
        text: "English",
        value: "en",
      },
      {
        text: "Hindi",
        value: "hi",
      },
    ],
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    setCurrentWindow() {
      this.$emit("setCurrentWindow", "other_instructions");
    },
    setInstructionsLang(val) {
      this.$emit("setInstructionsLang", val);
    },
  },

  mounted() {
    this.loading = false;
    this.instructionsLangLocal = this.instructionsLang;
  },
};
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

ol > li {
  margin-top: 0.5rem;
}

.icon-legend-div > div {
  margin-top: 0.5rem;
}
</style>