<template>
  <div id="test" class="fill-height indigo lighten-5">
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
    <!-- TODO: put loading, error, data state conditions -->

    <template v-if="loading">
      <h1 class="text-h1 text-center">Loading...</h1>
    </template>

    <template v-else-if="error">
      <v-alert type="error" text prominent>
        <h1 class="text-h1 text-center">{{ error }}</h1>
      </v-alert>
    </template>

    <template
      v-else-if="testObjLocal != null && testObjLocal.dataFetched == true"
    >
      <div v-if="currentWindow == 'general_instructions'">
        <GeneralInstructions
          :superLoading="loading"
          :instructionsLang="instructionsLang"
          :userData="userData"
          :testObj="testObjLocal"
          :modalView="false"
          @setInstructionsLang="setInstructionsLang"
          @setSuperLoading="setLoading"
          @setCurrentWindow="setCurrentWindow"
        />
      </div>

      <div v-else-if="currentWindow == 'other_instructions'">
        <OtherInstructions
          :superLoading="loading"
          :instructionsLang="instructionsLang"
          :userData="userData"
          :testObj="testObjLocal"
          :modalView="false"
          @setInstructionsLang="setInstructionsLang"
          @setSuperLoading="setLoading"
          @showSnackbar="showSnackbar"
          @setError="setError"
          @setTestLang="setTestLang"
          @setCurrentWindow="setCurrentWindow"
          @startTest="startTest"
        />
      </div>

      <div v-else-if="currentWindow == 'test'">
        <TestInterface
          :superLoading="loading"
          :testLang="testLang"
          :testObj="testObjLocal"
          :allQuestions="allQuestions"
          :userData="userData"
          :reAttempt="reAttempt"
          :usePersistedState="usePersistedState"
          @setSuperLoading="setLoading"
          @showSnackbar="showSnackbar"
          @setError="setError"
          @setTestLang="setTestLang"
          @setCurrentWindow="setCurrentWindow"
          @showResult="showResult"
        />
      </div>
      <div v-else-if="currentWindow == 'result'">
        <div class="my-6 text-center">
          <span class="text-h6">Loading Result:</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrBefore);

import GeneralInstructions from "@/components/Home/Test/GeneralInstructions.vue";
import OtherInstructions from "@/components/Home/Test/OtherInstructions.vue";
import TestInterface from "@/components/Home/Test/TestInterface.vue";

export default {
  name: "Test",

  beforeRouteLeave(to, from, next) {
    const currentWindow = this.currentWindow;
    if (currentWindow == "test") {
      const answer = window.confirm(
        "Do you really want to leave? Your progress will be discarded if not submitted."
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  metaInfo: {
    title: "Test",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Test Page description lorem ipsum dolor sit amet.",
      },
    ],
  },

  props: ["testObj", "reAttempt"],

  components: { GeneralInstructions, OtherInstructions, TestInterface },

  computed: {
    auth() {
      return this.$store.getters.auth;
    },

    userData() {
      return this.$store.getters.userData;
    },

    isUserSubscribed() {
      const userDataObj = this.userData;
      if (userDataObj && "subscribedTill" in userDataObj) {
        const userSubscribedTill = dayjs(
          userDataObj.subscribedTill.seconds * 1000
        );
        return dayjs().isSameOrBefore(userSubscribedTill);
      }
      return false;
    },
  },

  data: () => ({
    // Page State
    loading: true,
    discount: false,
    snackbar: false,
    snackbarText: "",
    error: "",
    currentWindow: "",
    instructionsLang: "en",
    testLang: "",
    testObjLocal: null,
    allQuestions: {}, //all test questions with key id
    usePersistedState: false,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    },

    setError(val) {
      this.loading = false;
      this.error = val;
    },

    setInstructionsLang(val) {
      this.setToActiveTestData('instructionsLang', val);
      this.instructionsLang = val;
    },

    setCurrentWindow(val) {
      this.currentWindow = val;
    },

    setTestLang(val) {
      this.setToActiveTestData('testLang', val);
      this.testLang = val;
    },

    startTest() {
      this.currentWindow = "test";
    },

    showResult(resultObj) {
      this.currentWindow = "result";

      var testObj = this.testObjLocal;
      testObj.dataFetched = false;

      // re-route to result page
      this.$router.push({
        name: "Result",
        params: {
          id: resultObj.testID,
          testObj,
          resultObj,
          allQuestions: this.allQuestions,
        },
      });
    },

    // also re-routes if user is not subbed and test is premium
    // re-routes if test is expired
    fetchTestData(testRef, isObj) {
      // check if activeTestData is for the current test;
      const activeTestData = this.getActiveTestData();
      if('testObjLocal' in activeTestData && activeTestData.testObjLocal.dataFetched == true ){
        // if it is : populate state and start test
        if(activeTestData.testObjLocal.id == `${isObj ? testRef.id : testRef}`){
          this.instructionsLang = activeTestData.instructionsLang;
          this.testLang = activeTestData.testLang;
          this.testObjLocal = activeTestData.testObjLocal;
          this.allQuestions = activeTestData.allQuestions;
          this.usePersistedState = true;
          this.setLoading(false);
          this.startTest();
          return;
        }else{
          // reset activeTestData
          this.resetActiveTestData();
        }
      }

      var payload = {
        testRef,
        testQuestions: null,
        isObj,
      };

      //true if testObj is passed
      //false if testID is passed
      if (isObj) {
        payload.testQuestions = testRef.selectedQuestions;
      } else {
        payload.testRef = testRef.toString(); //testRef is testID
      }

      //if isObj is true: gets questionData only
      //if isObj is false: gets both testObj and questionData
      this.$store
        .dispatch("getTestData", payload)
        .then((testDataRes) => {
          //  testDataRes= {questionsData, testObj, testResultObj}

          //premium check
          //if isObj is false: testData is {questionData, testObj} else {questionData}
          if (!isObj) {
            const testValidFrom = dayjs(
              testDataRes.testObj.startDateTime.seconds * 1000
            );

            const testValidTill = dayjs(
              testDataRes.testObj.endDateTime.seconds * 1000
            );

            if (
              testDataRes.testObj.isPremium &&
              !this.isUserSubscribed &&
              testDataRes.testResultObj == null
            ) {
              this.$router.push("/premium-pass");
              return;
            }

            //check if test is available
            else if (dayjs().isBefore(testValidFrom)) {
              this.$router.push("/404");
            }

            //check if test is expired
            else if (!dayjs().isSameOrBefore(testValidTill)) {
              this.$router.push("/404");
              return;
            }
            this.testObjLocal = testDataRes.testObj;
          }

          //all questions data
          this.allQuestions = testDataRes.questionsData.questionsDataObj;
          this.setToActiveTestData('allQuestions', this.allQuestions);

          //replace folders in testObjLocal.selectedQuestions with array of questions
          //(or in other words: make it like manually selected Questions)
          //for the respective folder
          Object.keys(this.testObjLocal.selectedQuestions).forEach(
            (section) => {
              //check if it is not an array: then it is an obj {folderName: ''}
              if (
                this.testObjLocal.selectedQuestions[section].constructor !==
                Array
              ) {
                let folderName =
                  this.testObjLocal.selectedQuestions[section].folderName;

                this.$set(
                  this.testObjLocal.selectedQuestions,
                  section,
                  testDataRes.questionsData.folderQuestions[folderName]
                );
              }
            }
          );

          // test already attempted: re-route to result page
          if (!this.reAttempt && testDataRes.testResultObj != null) {
            this.showResult(testDataRes.testResultObj);
          }

          this.testObjLocal.dataFetched = true;
          this.setToActiveTestData('testObjLocal', this.testObjLocal);

          this.currentWindow = "general_instructions";
          this.setLoading(false);
        })
        .catch((error) => {
          this.error = error;
          this.setLoading(false);
        });
    },

    setToActiveTestData(key, data){
      this.$store.dispatch("setToActiveTestData", {key, data});
    },

    // not computed so it doesn't get cached.
    getActiveTestData(){
      return this.$store.getters.activeTestData;
    },

    resetActiveTestData(){
      this.$store.dispatch("resetActiveTestData");
    }
  },

  mounted() {
    const testID = this.$route.params.id;

    // check valid test id
    if (isNaN(testID) || testID.toString().length !== 10) {
      this.$router.push("/404");
      return;
    }

    const testObj = this.testObj;

    //check if testObj exists
    if (typeof testObj === "object" && testObj !== null) {
      const testValidFrom = dayjs(testObj.startDateTime.seconds * 1000);
      const testValidTill = dayjs(testObj.endDateTime.seconds * 1000);

      if (testObj.isPremium && !this.isUserSubscribed) {
        this.$router.push("/premium-pass");
      }

      //check if test is available
      else if (dayjs().isBefore(testValidFrom)) {
        this.$router.push("/404");
      }

      //check if test is expired
      else if (!dayjs().isSameOrBefore(testValidTill)) {
        this.$router.push("/404");
      }

      //test obj exists; no need to fetch it from server
      this.testObjLocal = this.testObj;
      this.setToActiveTestData('instructionsLang', 'en');
      this.fetchTestData(this.testObjLocal, true);
    } else {
      // no valid testObj found: fetch from server
      this.setToActiveTestData('instructionsLang', 'en');
      this.fetchTestData(testID, false);
    }
  },
};
</script>

<style>
.htmlFromEditor img {
  max-width: 100% !important;
  height: auto;
}

.legend-icon {
  background: url("../../assets/home/test/questions-sprite.png") no-repeat
    scroll 0 0 transparent;
  float: left;
  font-weight: bold;
  height: 38px;
  line-height: 10px;
  width: 34px;
  color: #474747;
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.legend-icon > span {
  display: inline-block;
}

.icon-not-visited {
  background-position: -105px -49px;
}

.icon-not-answered {
  background-position: -39px -48px;
  color: #fff !important;
}

.icon-answered {
  background-position: -5px -48px;
}

.icon-review {
  background-position: -72px -48px;
  color: #fff !important;
}

.icon-review-marked {
  background-position: -169px -49px;
  color: #fff !important;
}
</style>