<template>
  <div justify="center" id="questionInterface" class="mx-0 px-0 fill-height">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
          <v-card
            height="67vh"
            class="d-flex flex-column mx-0 mx-md-3"
            elevation="2"
          >
            <!-- Question Number  -->
            <div
              class="
                deep-purple--text
                text--darken-2 text-subtitle-2
                pl-3
                mt-2
                ml-1
              "
            >
              <v-chip label> Question No. {{ questionIndex + 1 }} </v-chip>
            </div>

            <!-- Overflowed area (Actual Question and options) -->
            <v-card-text class="black--text">
              <!-- Question and Options -->
              <div>
                <!-- English Question -->
                <template v-if="testLang == 'en' || !question.isBilingual">
                  <div class="ml-1 ml-md-6">
                    <span
                      class="htmlFromEditor"
                      v-html="question.question.Primary"
                    ></span>
                  </div>

                  <!-- Options Div -->
                  <div class="mt-4">
                    <v-radio-group v-model="answer">
                      <v-container fluid>
                        <!-- Option loop -->
                        <v-row
                          align="center"
                          no-gutters
                          v-for="(optionIndex, i) in question.options"
                          :key="i"
                        >
                          <v-col class="d-flex" cols="12" sm="12">
                            <span class="mr-2 d-flex align-start">
                              <v-radio :value="optionIndex">
                                <template slot="label">
                                  <span
                                    class="mt-4 ml-1 ml-md-3 htmlFromEditor"
                                    v-html="
                                      question.optionsData[optionIndex].Primary
                                    "
                                  ></span>
                                </template>
                              </v-radio>
                            </span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-radio-group>
                  </div>
                </template>

                <!-- Hindi Question -->
                <template v-else>
                  <span
                    class="htmlFromEditor"
                    v-html="question.question.Secondary"
                  ></span>

                  <!-- Options Div -->
                  <div class="mt-4">
                    <v-radio-group v-model="answer">
                      <v-container fluid>
                        <!-- Option Loop -->
                        <v-row
                          align="center"
                          no-gutters
                          v-for="(optionIndex, i) in question.options"
                          :key="i"
                        >
                          <v-col class="d-flex" cols="12" sm="12">
                            <span class="mr-2 d-flex align-start">
                              <v-radio :value="optionIndex">
                                <template slot="label">
                                  <span
                                    class="mt-4 ml-1 ml-md-3 htmlFromEditor"
                                    v-html="
                                      question.optionsData[optionIndex]
                                        .Secondary
                                    "
                                  ></span>
                                </template>
                              </v-radio>
                            </span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-radio-group>
                  </div>
                </template>
              </div>

              <!-- Options Div -->
            </v-card-text>

            <!-- Navigation Buttons -->
            <v-footer class="pa-2 d-flex justify-start">
              <v-btn
                class="ma-1"
                @click.prevent="markForReviewAndNext()"
                outlined
                tile
                :small="$vuetify.breakpoint.mobile"
              >
                Mark for Review &amp; Next
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>

              <v-btn
                class="ma-1"
                @click.prevent="clearResponse()"
                outlined
                tile
                :small="$vuetify.breakpoint.mobile"
              >
                Clear Response
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                class="ma-1"
                color="primary"
                @click.prevent="saveAndNext()"
                :small="$vuetify.breakpoint.mobile"
              >
                Save &amp; Next
                <v-icon class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
            </v-footer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "QuestionInterface",
  props: [
    "superLoading",
    "testLang",
    "question",
    "questionIndex",
    "selectedAnswer",
  ],

  watch: {
    // get answer everytime question changes
    // reset if selectedAnswer does not exist
    question: function () {
      if (this.selectedAnswer) {
        this.answer = this.selectedAnswer;
      } else {
        this.answer = "";
      }
    },
  },

  data: () => ({
    loading: false,
    answer: "",
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    setCurrentWindow() {
      this.$emit("setCurrentWindow", "other_instructions");
    },
    markForReviewAndNext() {
      this.$emit("markForReviewAndNext", this.answer);
    },
    clearResponse() {
      this.answer = "";
      this.$emit("clearResponse");
    },
    saveAndNext() {
      this.$emit("saveAndNext", this.answer);
    },
  },

  mounted() {
    this.loading = false;
    this.instructionsLangLocal = this.instructionsLang;
  },
};
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

ol > li {
  margin-top: 0.5rem;
}

.icon-legend-div > div {
  margin-top: 0.5rem;
}
</style>