<template>
  <v-navigation-drawer
    v-model="drawerComputed"
    id="testSidebar"
    right
    fixed
    app
    hide-overlay
  >
    <!-- User Profile and Data -->
    <v-list-item>
      <v-list-item-icon class="mx-auto">
        <v-avatar size="40" v-if="userData.photoURL">
          <v-img :src="userData.photoURL"></v-img>
        </v-avatar>

        <v-icon v-else size="40">mdi-account-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="mx-auto text-center">
        <v-list-item-title class="text-button">{{
          userData.fullName
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mt-2 mb-1"></v-divider>

    <!-- Icon Legend -->
    <v-container fluid>
      <v-row justify="start" align="center">
        <template v-for="(legendClass, i) in legendIconClasses">
          <v-col
            cols="12"
            :sm="i == 4 ? '12' : '6'"
            class="pa-1"
            :key="legendClass.className"
          >
            <div class="d-flex justify-start align-center mx-1">
              <div :class="`legend-icon ${legendClass.className}`">
                <span class="text-no-wrap"> 1 </span>
              </div>
              <small class="ml-2"> {{ legendClass.legendText }} </small>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <v-divider class="my-2"></v-divider>

    <!-- Question Button Loop and Section Name -->
    <v-container v-if="sectionName" fluid>
      <v-row justify="start" align="start" class="pa-0 ma-0">
        <v-col cols="12" sm="12" class="pa-0 ma-0">
          <v-alert
            class="text-center white--text"
            dense
            color="deep-purple lighten-1"
          >
            {{ sectionName }}
          </v-alert>

          <small class="font-weight-bold">Choose a question:</small>
        </v-col>

        <template v-for="(questionID, i) in questionsArr">
          <v-col
            cols="3"
            sm="3"
            class="pa-1 ma-0"
            :key="`question_${questionID}`"
          >
            <div class="d-flex justify-start align-center mx-1">
              <div
                @click="questionClick(questionID)"
                :class="`legend-icon ${getQuestionIcon(questionID)}`"
              >
                <span class="text-no-wrap"> {{ i + 1 }} </span>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- Submit Button at bottom of drawer if Authenticated -->
    <template v-slot:append>
      <div class="pa-2">
        <v-btn @click="submitTest" block depressed>
          <v-icon class="mr-2">mdi-check</v-icon> Submit
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
  
<script>
export default {
  name: "TestSidebar",
  props: ["drawer", "userData", "sectionName", "questionsArr", "questionState"],
  computed: {
    drawerComputed: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("setDrawer", value);
      },
    },
  },
  data: () => ({
    loading: true,
    valid: false,
    legendIconClasses: [
      {
        className: "icon-not-visited",
        legendText: "Not Visited",
      },
      {
        className: "icon-not-answered",
        legendText: "Not Answered",
      },
      {
        className: "icon-answered",
        legendText: "Answered",
      },
      {
        className: "icon-review",
        legendText: "Marked for review",
      },
      {
        className: "icon-review-marked",
        legendText: "Answered and marked for review (will not be evaluated)",
      },
    ],
  }),
  methods: {
    questionClick(questionID) {
      this.$emit("questionClicked", questionID);
    },
    submitTest() {
      this.$emit("submitTest", "submit");
    },
    getQuestionIcon(questionID) {
      const state = this.questionState[questionID];
      if (state) {
        return "icon-" + state;
      }

      return "icon-not-visited";
    },
  },
};
</script>